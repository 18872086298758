import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { FaCaretDown } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { medicalEvalSvc } from "../../services/medEvals.service";

const getDaysInMonth = (year: number, month: number) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const getLeadingDays = (year: number, month: number) => {
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const leadingDays = [];
  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    leadingDays.push(new Date(year, month, -i));
  }
  return leadingDays;
};

const getTrailingDays = (year: number, month: number) => {
  const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
  const trailingDays = [];
  for (let i = 1; i < 7 - lastDayOfMonth; i++) {
    trailingDays.push(new Date(year, month + 1, i));
  }
  return trailingDays;
};

interface CalendarProps {
  onDateTimeSelect: (date: Date, time?: string) => void;
  unavailableDateTimes?: string[];
}

const Calendar: React.FC<CalendarProps> = ({
  onDateTimeSelect,
  unavailableDateTimes = [],
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [view, setView] = useState<"datePicker" | "timePicker">("datePicker");
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<any>([]);

  const isPrevMonthDisabled =
    currentDate.getFullYear() <= new Date().getFullYear() &&
    currentDate.getMonth() <= new Date().getMonth();

  const getTimeSlots = async (date: Date | string) => {
    try {
      const resp = await medicalEvalSvc.getAvailableSlots(date);
      if (resp) {
        setTimeSlots(resp);
      } else {
        console.error("No available slots found.");
        setTimeSlots([]);
      }
    } catch (error) {
      console.error("Failed to get time slots:", error);
      setTimeSlots([]);
    }
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (date: Date) => {
    if (isSelectable(date)) {
      setSelectedDate(date);
      onDateTimeSelect(date);
      getTimeSlots(date);
      setView("timePicker");
    }
  };

  const handleBackToDatePicker = () => {
    setView("datePicker");
    setSelectedTime(null);
  };

  const handleMonthChange = (monthIndex: number) => {
    const newDate = new Date(currentDate.getFullYear(), monthIndex, 1);
    setCurrentDate(newDate);
  };

  const handleTimeSelect = (time: string) => {
    if (selectedDate) {
      onDateTimeSelect(selectedDate, time);
      setSelectedTime(time);
    }
  };

  const isSelectable = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight
    const isFutureDay = date > today; // Check if the date is today or in the future
    const isTuesdayOrThursday = date.getDay() === 2 || date.getDay() === 4; // Check if the date is Tuesday or Thursday
    const isAvailable = !unavailableDateTimes.includes(date.toDateString()); // Check if the date is not in unavailableDateTimes
    return isFutureDay && isTuesdayOrThursday && isAvailable;
  };

  const isTimeSlotAvailable = (slot: string) => {
    if (!selectedDate) return true;
    const dateTimeString = `${selectedDate.toDateString()}/${slot}`;
    return !unavailableDateTimes.includes(dateTimeString);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const daysInMonth = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const leadingDays = getLeadingDays(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const trailingDays = getTrailingDays(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  return (
    <div className="">
      {view === "datePicker" && (
        <div>
          <h4 className="font-semibold text-2xl pb-3 border-b border-secondaryVariant2/50">
            {selectedDate ? selectedDate.toDateString() : "Select a date"}
          </h4>
          <div className="flex items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="text-secondaryVariant p-3 text-base min-w-[136px] flex items-center justify-between focus:outline-none">
                {`${
                  monthNames[currentDate.getMonth()]
                } ${currentDate.getFullYear()}`}{" "}
                <FaCaretDown className="text-secondaryVariant" />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className="bg-white shadow-md rounded-md max-h-[300px] overflow-y-auto">
                {Array.from({ length: 12 }).map((_, i) => (
                  <DropdownMenu.Item
                    key={i}
                    className={`p-2  ${
                      i < new Date().getMonth() &&
                      currentDate?.getFullYear() === new Date().getFullYear()
                        ? "text-gray-400 cursor-text"
                        : "cursor-pointer hover:bg-gray-200"
                    }`}
                    onSelect={() => handleMonthChange(i)}
                    disabled={
                      i < new Date().getMonth() &&
                      currentDate?.getFullYear() === new Date().getFullYear()
                    }
                  >
                    {monthNames[i]} {currentDate.getFullYear()}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <div className="flex items-center flex-1 justify-end gap-2 w-full">
              <button
                onClick={handlePrevMonth}
                disabled={isPrevMonthDisabled}
                className={`bg-white rounded-full ${
                  isPrevMonthDisabled
                    ? "text-secondaryVariant2"
                    : "text-gray-400"
                }`}
              >
                <FiChevronLeft size={24} />
              </button>
              <button
                onClick={handleNextMonth}
                className="bg-white rounded-full"
              >
                <FiChevronRight size={24} className="text-gray-400" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-7">
            {daysOfWeek.map((day, index) => (
              <div
                key={day + index}
                className="h-12 flex items-center justify-center text-center text-primary"
              >
                {day}
              </div>
            ))}
            {leadingDays.map((day) => (
              <div
                key={day.toDateString()}
                className="h-12 flex items-center justify-center text-center text-gray-400"
              >
                {day.getDate()}
              </div>
            ))}
            {daysInMonth.map((day) => (
              <div
                key={day.toDateString()}
                className={`text-center h-12 flex items-center justify-center ${
                  isSelectable(day)
                    ? "cursor-pointer"
                    : "text-gray-400 cursor-text"
                }`}
                onClick={() => handleDateClick(day)}
              >
                <span
                  className={`w-10 h-10 rounded-full inline-flex items-center justify-center ${
                    isSelectable(day) ? "hover:bg-primary hover:text-white" : ""
                  } ${
                    day.toDateString() === selectedDate?.toDateString()
                      ? "bg-primary text-white"
                      : ""
                  }`}
                >
                  {day.getDate()}
                </span>
              </div>
            ))}
            {trailingDays.map((day) => (
              <div
                key={day.toDateString()}
                className="h-12 flex items-center justify-center text-center text-gray-400"
              >
                {day.getDate()}
              </div>
            ))}
          </div>
        </div>
      )}
      {view === "timePicker" && (
        <div>
          <div className="flex items-center gap-3 pb-3 border-b border-secondaryVariant2/50">
            <button onClick={handleBackToDatePicker}>
              <IoMdArrowBack size={24} />
            </button>
            <div className="flex-1">
              <h4 className="font-semibold text-2xl">Time Slots</h4>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 pt-4">
            {timeSlots.availableSlots &&
              timeSlots.availableSlots.map((slot: string, index: number) => (
                <div key={slot + index}>
                  <div className="flex items-center me-4">
                    <input
                      id={`slot-radio-${slot + index}`}
                      type="radio"
                      value={slot}
                      name="time-slot"
                      className="appearance-none w-0 h-0 opacity-0 invisible peer"
                      onChange={() => handleTimeSelect(slot)}
                      disabled={!isTimeSlotAvailable(slot)}
                    />
                    <label
                      htmlFor={`slot-radio-${slot + index}`}
                      className={`relative flex items-center gap-3 cursor-pointer before:content-[''] before:w-5 before:h-5 before:border before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-tertiary peer-checked:before:bg-tertiary peer-checked:before:shadow-[inset_0_0_0_2px_#fff] ${
                        !isTimeSlotAvailable(slot)
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {slot}
                    </label>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
