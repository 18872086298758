import React from "react";
import { RxArrowRight } from "react-icons/rx";
import { MdArrowBackIos } from "react-icons/md";

interface HeroBannerProps {
  paragraphText: string;
  showStats?: boolean;
  title?: string;
  highlightedText?: string;
  bgClass?: string;
  isButton?: boolean;
  backButton?: boolean;
  buttonText?: any;
  backButtonText?: any;
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  paragraphText,
  showStats,
  title,
  highlightedText,
  bgClass,
  isButton,
  backButton,
  buttonText,
  backButtonText,
}) => {
  return (
    <>
      <section
        className={`bg-no-repeat bg-cover bg-right`}
        style={{ backgroundImage: `url(${bgClass})` }}
      >
        <div className="lg:bg-banner h-[150px] md:h-[250px] lg:h-[320px] flex items-center bg-right-bottom bg-contain bg-no-repeat">
          <div className="container">
            <div className="grid lg:grid-cols-3 gap-4">
              <div className="lg:col-span-2">
                <div className="lg:bg-none bg-bannerGradiant xl:p-0 p-4 xl:rounded-none rounded-lg xl:max-w-auto max-w-[525px]">
                  {backButton ? (
                    <button className="text-primary flex items-center gap-2 text-lg mb-6">
                      <MdArrowBackIos className="w-5 h-5" /> {backButtonText}
                    </button>
                  ) : null}
                  {title && (
                    <h2 className="text-secondary font-semibold xl:text-4xl lg:text-3xl text-2xl mb-6 max-w-[532px]">
                      {title} <i className="text-primary">{highlightedText}</i>
                    </h2>
                  )}
                  <p className="xl:text-18 text-base max-w-[470px]">
                    {paragraphText}
                  </p>
                  {isButton ? (
                    <button className="btnPrimary flex items-center gap-4 justify-center max-w-[246px] mt-6">
                      {buttonText} <RxArrowRight className="w-5 h-5" />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroBanner;
