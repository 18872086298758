import React from "react";
import { FiArrowDownRight } from "react-icons/fi";
import { CgChevronRight } from "react-icons/cg";

const daysData = [
  { id: 1, day: "01", chevronText: "3" },
  { id: 2, day: "01", chevronText: "3" },
  { id: 3, day: "01", chevronText: "3" },
  { id: 4, day: "01", chevronText: "3" },
  { id: 5, day: "01", chevronText: "3" },
  { id: 6, day: null, chevronText: null }, 
  { id: 7, day: null, chevronText: null },
];

const CalendarData = () => {
  return (
    <>
      <div className="flex">
        {daysData.map((item, index) => (
          <div
            key={item.id}
            className={`ps-2 py-2 w-[65px] h-[72px] ${
              item.day ? "bg-[#F1F8FF]" : "bg-secondaryVariant2"
            } flex-[0_0_65px]`}
          >
            {item.day && (
              <div className="flex items-start justify-between">
                <button>
                  <FiArrowDownRight className="w-3 h-3 text-arrowColor" />
                </button>
                <div className="flex flex-col items-end gap-1">
                  <div className="bg-statusColor text-base text-white font-semibold py-1 pe-3 ps-2.5 rounded rounded-l-full">
                    {item.day}
                  </div>
                  <div className="bg-ageViolation text-xs text-secondary font-semibold py-1 pe-3 ps-1.5 rounded rounded-l-full flex items-center">
                    <CgChevronRight className="w-3 h-3" />
                    {item.chevronText}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CalendarData;
