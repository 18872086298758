import React from "react";
import { HiOutlineTv } from "react-icons/hi2";
import { FiArrowDown } from "react-icons/fi";
import { FiArrowDownRight } from "react-icons/fi";
import { FiArrowUpRight } from "react-icons/fi";
import { FiArrowUp } from "react-icons/fi";

const AutoMovements = () => {
  return (
    <>
      <div className="bg-white rounded-lg p-2">
        <div className="flex items-end gap-2">
          <div className="bg-secondaryNeutral py-2 px-3 rounded-lg flex items-start w-[471px] 2xl:w-full">
            <div className="flex flex-col col-span-4 w-full items-start ">
              <h4 className="text-sm text-primary font-semibold w-32">
                Auto Movements
              </h4>
              <span className="text-sm text-secondaryVariant">Tomorrow:</span>
              <span className="text-sm text-secondaryVariant">Today:</span>
            </div>
            <div className="flex flex-col col-span-2 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowDown className="w-3 h-3" />
                Starts{" "}
              </h4>
              <span className="text-sm text-secondaryVariant">06</span>
              <span className="text-sm text-secondaryVariant">01</span>
            </div>
            <div className="flex flex-col col-span-3 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowDownRight className="w-3 h-3" />
                Transitions <FiArrowUpRight className="w-3 h-3" />
              </h4>
              <span className="text-sm text-secondaryVariant">06</span>
              <span className="text-sm text-secondaryVariant">01</span>
            </div>
            <div className="flex flex-col col-span-3 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowUp className="w-3 h-3" />
                Withdrawals{" "}
              </h4>
              <span className="text-sm text-secondaryVariant">06</span>
              <span className="text-sm text-secondaryVariant">01</span>
            </div>
          </div>
          <div className="bg-secondaryNeutral p-3 rounded-lg flex items-center gap-3 w-[444px] 2xl:w-full">
            <div className="flex items-center gap-1 2xl:w-full">
              <span className="w-4 h-3 bg-statusColor rounded rounded-l-full block"></span>{" "}
              Vacancy
            </div>
            <div className="flex items-center gap-1 2xl:w-full">
              <span className="w-4 h-3 bg-overBooked rounded rounded-l-full block"></span>{" "}
              Over-Booked
            </div>
            <div className="flex items-center gap-1 2xl:w-full">
              <span className="w-4 h-3 bg-white rounded rounded-l-full block"></span>{" "}
              Full
            </div>
            <div className="flex items-center gap-1 2xl:w-full">
              <span className="w-4 h-3 bg-ageViolation rounded rounded-l-full block"></span>{" "}
              Age Violation
            </div>
          </div>
          <div className="bg-secondaryNeutral p-3 rounded-lg flex items-center gap-2">
            <HiOutlineTv className="text-secondaryVariant w-6 h-6" />
            <span className="text-sm text-secondaryVariant">Min</span>

            <input
              className="rounded-full overflow-hidden appearance-none bg-white h-3 w-[233px]"
              type="range"
              min="1"
              max="100"
              step="1"
              value="15"
            />
            <span className="text-sm text-secondaryVariant">Max</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoMovements;
