import React from "react";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import EnrolTrackingOptimization from "../../components/enrolTrackingOptimization/EnrolTrackingOptimization";
import AutoMovements from "../../components/autoMovements/AutoMovements";
import EnrollmentCalendar from "../../components/enrollmentCalendar/EnrollmentCalendar";
import SessionTime from "../../components/Session/SessionTime";
import CalendarData from "../../components/enrollmentCalendar/CalendarData";

const EnrollmentTracking = ({month,year}:any) => {
  return (
    <>
      <DashboardWrapper>
        <div className="ps-6 pt-5 bg-secondaryNeutral">
          <div className="pe-6 mb-6">
            <EnrolTrackingOptimization />
            <AutoMovements />
          </div>
          <div className="flex border-t-[0.5px]">
            <div className="w-[300px]">
              <div className="bg-white  ps-3 py-2">
                <h2 className="text-base text-primary font-semibold">
                  {month}, {year}
                </h2>
              </div>
              <div className="bg-primaryVariant2 flex items-center gap-[50px] px-3 py-1.5">
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Classroom
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Session
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Wait
                </h3>
              </div>
              <div className="mb-6">
                <SessionTime />
              </div>
              <div className="mb-6">
                <SessionTime />
              </div>
              <div className="mb-6">
                <SessionTime />
              </div>
            </div>
            <div className="flex-1 overflow-x-scroll w-[calc(100vw-400px)] custom-scrollbar">
              <EnrollmentCalendar />
              <div className="bg-primaryVariant2 py-[17.5px]"></div>
              <div className="flex gap-0 m-0 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
              <div className="flex gap-0 mb-6 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
              <div className="flex gap-0 m-0 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
              <div className="flex gap-0 mb-6 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
              <div className="flex gap-0 m-0 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
              <div className="flex gap-0 mb-6 p-0">
                <CalendarData />
                <CalendarData />
                <CalendarData />
                <CalendarData />
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EnrollmentTracking;
