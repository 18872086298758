import React from "react";
import { TiArrowSortedDown } from "react-icons/ti";

const SessionTime = () => {
  return (
    <>
      <div className="bg-white ps-3 pe-2 py-1.5">
        <div className="flex items-start gap-3 pb-3">
          <div className="rounded-lg py-1 px-2 max-w-[119px] w-full bg-secondary">
            <div className="flex items-start pt-1 justify-between">
              <h3 className="text-base font-semibold text-white">Infant</h3>
              <span className="text-white bg-primary px-1.5 rounded-3xl">
                19
              </span>
            </div>
            <strong className="text-base font-semibold text-white">
              0-6 months
            </strong>
          </div>
          <div className="flex items-start justify-between w-full">
            <div className="bg-successAlert py-1 px-1.5 text-white text-xs font-medium rounded">
              Full-time
            </div>
            <div className="text-base text-secondary font-bold ">04</div>
          </div>
        </div>
        <div className="flex items-start gap-3">
          <div className="max-w-[119px] h-[60px] w-full"></div>
          <div className="flex items-start justify-between w-full">
            <button className="flex items-center gap-1">
              <span className="bg-ageViolation py-1 px-1.5 text-white text-xs font-medium rounded ">
                Part-time{" "}
              </span>

              <TiArrowSortedDown className="text-secondary w-6 h-6" />
            </button>
            <strong className="text-base text-secondary font-bold">04</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionTime;
