import React, { useState } from "react";

const EnrolTrackingOptimization = () => {
  // Use `any` type for the state
  const [switches, setSwitches] = useState<any>({
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
    switch5: false,
  });

  // Handle toggle function
  const handleToggle = (switchName: string) => {
    setSwitches((prevState: any) => ({
      ...prevState,
      [switchName]: !prevState[switchName],
    }));
  };

  return (
    <div className="flex items-center justify-between py-5">
      <h2 className="text-2xl text-secondary font-semibold">
        Enrollment Tracking & Optimization
      </h2>
      <div className="flex items-center gap-3">
        {/* Switch 1 */}
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary">Strategic View</span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="switch1"
              className="hidden peer"
              checked={switches.switch1}
              onChange={() => handleToggle("switch1")}
            />
            <label
              htmlFor="switch1"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary">Enrollment No.</span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="switch2"
              className="hidden peer"
              checked={switches.switch2}
              onChange={() => handleToggle("switch2")}
            />
            <label
              htmlFor="switch2"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary">Age Violation</span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="switch3"
              className="hidden peer"
              checked={switches.switch3}
              onChange={() => handleToggle("switch3")}
            />
            <label
              htmlFor="switch3"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary">Movements</span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="switch4"
              className="hidden peer"
              checked={switches.switch4}
              onChange={() => handleToggle("switch4")}
            />
            <label
              htmlFor="switch4"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary">Optimization</span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="switch5"
              className="hidden peer"
              checked={switches.switch5}
              onChange={() => handleToggle("switch5")}
            />
            <label
              htmlFor="switch5"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        {/* Repeat similar structure for other switches */}
      </div>
    </div>
  );
};

export default EnrolTrackingOptimization;
