import React from "react";

const EnrollmentCalendar = () => {
  const daysInMonth = [
    { day: "Mon", date: "01" },
    { day: "Tue", date: "02" },
    { day: "Wed", date: "03" },
    { day: "Thu", date: "04" },
    { day: "Fri", date: "05" },
    { day: "Sat", date: "06" },
    { day: "Sun", date: "07" },
    { day: "Mon", date: "08" },
    { day: "Tue", date: "09" },
    { day: "Wed", date: "10" },
    { day: "Thu", date: "11" },
    { day: "Fri", date: "12" },
    { day: "Sat", date: "13" },
    { day: "Sun", date: "14" },
    { day: "Mon", date: "15" },
    { day: "Tue", date: "16" },
    { day: "Wed", date: "17" },
    { day: "Thu", date: "18" },
    { day: "Fri", date: "19" },
    { day: "Sat", date: "20" },
    { day: "Sun", date: "21" },
    { day: "Mon", date: "22" },
    { day: "Tue", date: "23" },
    { day: "Wed", date: "24" },
    { day: "Thu", date: "25" },
    { day: "Fri", date: "26" },
    { day: "Sat", date: "27" },
    { day: "Sun", date: "28" },
    { day: "Mon", date: "29" },
    { day: "Tue", date: "30" },
  ];

  return (
    <div className="flex bg-white">
      {daysInMonth.map((dayObj, index) => (
        <div
          key={index}
          className={`flex items-end gap-2 border-b-[0.5px] ${
            ["Sat", "Sun"].includes(dayObj.day)
              ? "bg-secondaryVariant2"
              : "bg-white"
          } border-secondaryNeutral border-r-[0.5px] py-2 px-2.5 w-[65px] flex-[0_0_65px]`}
        >
          <span className="text-[10px] text-secondaryVariant">{dayObj.day}</span>
          <strong className="text-base text-secondaryVariant font-semibold">
            {dayObj.date}
          </strong>
        </div>
      ))}
    </div>
  );
};

export default EnrollmentCalendar;
